<template>
  <div>
    <submission_detail :data_params="data_params"></submission_detail>
    <el-form>
      <el-form-item style="text-align: center">
        <el-button type="danger" @click="reject">拒绝</el-button>
        <el-button type="primary" @click="accept">通过</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import submission_detail from "./submission_detail";
import {mapActions} from "vuex";
export default {
  name: "review_submission",
  components: {submission_detail},
  data() {
    return {
      loading: false,
      data_params: {}
    }
  },
  async mounted() {
    this.loading = true;
    let _id = this.$route.query._id;
    let act_id = this.$route.query.act_id;
    this.data_params = {
      _id,
      act_id
    };

    this.loading = false;
  },
  methods: {
    async accept() {
      const { errorCode, data } = await this.$http.acceptSubmission(this.data_params);
      if(errorCode == "0000") {
        this.data_params = {
          ...this.data_params
        }

        this.$message({
          message: '操作成功！',
          type: 'success'
        });

        this.$bus.$emit("onSubmissionChanged", data);
        this.removeTabByPath('/activities/review_submission');
        this.$router.push({
          path:'/activities/submissions',
          query: {'_id': this.data_params.act_id}
        });
      }
    },
    async reject() {
      const { errorCode, data } = await this.$http.rejectSubmission(this.data_params);
      if(errorCode == "0000") {
        this.data_params = {
          ...this.data_params
        }

        this.$message({
          message: '操作成功！',
          type: 'success'
        });

        this.$bus.$emit("onSubmissionChanged", data);
        this.removeTabByPath('/activities/review_submission');
        this.$router.push({
          path:'/activities/submissions',
          query: {'_id': this.data_params.act_id}
        });
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ])
  }
}
</script>

<style scoped>

</style>
